<template>
  <basicTable
      :tableData="tableData"
      :attrList="attrList"
      :page="page.current"
      :limit="page.limit"
      @currentChange="handleCurrentChange"
      @sizeChange="handleSizeChange"
      :total="page.total">
  </basicTable>
</template>

<script>
import {onMounted, reactive, toRefs} from "vue";
import {listData} from "../../api/motorroom/sensor";
  export default {
    setup(){
      let state = reactive({
        page: {
          current: 1,
          limit: 10,
          total: 0
        },
        tableData:[],
        attrList: [
          {
            label: "AI调用地址",
            prop: "aiUrl",
            render: (row) => <div>{row.aiUrl}</div>
          },
          {
            label: "DI调用地址",
            prop: "diUrl",
            render: (row) => <div>{row.diUrl}</div>
          },
          {
            label: "温度警戒值(℃)",
            prop: "temperatureBasic",
            render: (row) => <div>{row.temperatureBasic}</div>
          },
          {
            label: "湿度警戒值(%)",
            prop: "humidityBasic",
            render: (row) => <div>{row.humidityBasic}</div>
          },
          {
            label: "烟雾浓度警戒值(PPM)",
            prop: "smokeBasic",
            render: (row) => <div>{row.smokeBasic}</div>
          },
          {
            label: "cpu/内存检测定时(ms)",
            prop: "timingOne",
            render: (row) => <div>{row.timingOne}</div>
          },
          {
            label: "传感器检测定时(ms)",
            prop: "timingTwo",
            render: (row) => <div>{row.timingTwo}</div>
          },
          {
            label: "创建时间",
            prop: "createTime",
            render: (row) => <div>{row.createTime}</div>
          }
        ]
      })

      function onLoad(){
        listData({ page:state.page.current,limit:state.page.limit }).then(res=>{
          state.tableData = res.data.data.list;
          state.page.total = res.data.data.count;
        })
      }

      function handleCurrentChange(currentPage) {
        state.page.current = currentPage;
        onLoad();
      }

      function handleSizeChange(pageSize) {
        state.page.limit = pageSize;
        onLoad();
      }

      onMounted(()=>{
        onLoad();
      })
      return{
        handleCurrentChange,
        handleSizeChange,
        ...toRefs(state)
      }
    }
  }
</script>
<style lang="scss" scoped>

</style>